<template>
  <div>
    <ViewTemplateWithTable :panel="panel" :templateList="templateList">
      <div slot="content-buttons">
        <Button
          _key="btnUserCreate"
          title="Usuário"
          classIcon="fas fa-plus-circle"
          type="primary"
          size="small"
          eventName="userCreate"
          :clicked="create"
        />
      </div>
    </ViewTemplateWithTable>
  </div>
</template>

<script>
import ViewTemplateWithTable from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithTable.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "UsuarioListaView",
  components: {
    ViewTemplateWithTable,
    Button,
  },
  data() {
    return {
      panel: {
        module: "Adm",
        title: "Usuário",
        showFilter: true,
        showSearch: true,
        showButtons: true,
      },
      templateList: {
        urlGetApi: "/api/v1/adm/user/get-all",
        urlDeleteAllApi: "/api/v1/adm/user/delete",
        showChecks: true,
        headerTable: [
          {
            field: "name",
            title: "Nome",
            type: "link",
            iconSearch: true,
            routeName: "userUpdate",
          },
          {
            field: "typeName",
            title: "",
            type: "class",
            fieldComparison: "typeName",
            classCssBody: [
              {
                classCss: "badge badge-info text-center",
                fieldComparison: "Adm",
              },
              { classCss: "badge badge-warning text-center", fieldComparison: "Padrão" },
            ],
          },
          {
            field: "userName",
            title: "Login",
            type: "text",
            iconSearch: false,
          },
          {
            field: "email",
            title: "E-mail",
            type: "text",
            iconSearch: false,
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapMutations("generic", ["removeLoading"]),
    create() {
      this.$router.push({
        name: "userCreate",
      });
      this.removeLoading(["btnUserCreate"]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "userUpdate") {
          let self = this;
          setTimeout(function () {
            self.$router.push({
              name: "userUpdate",
              params: { id: event.data.id },
            });
            self.removeLoading([event.data.id]);
          }, 300);
        }
      },
      deep: true,
    },
  },
};
</script>
